import React, {Component} from "react";
import Stats from "./Stats";
import Timeline from "./Timeline";


class PageTwo extends Component {
    render() {
        return(
            <>
                <Stats></Stats>
                <Timeline></Timeline> 

            </>
        )
    }
}


export default PageTwo;