import React from "react";
import PageOne from "./components/page1/PageOne";
import PageTwo from "./components/page2/PageTwo";
import Nav from './components/Nav';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageThree from "./components/page3/PageThree";
import PageFour from "./components/page4/PageFour";
import PageFive from "./components/page5/PageFive";
import PageSix from "./components/page6/PageSix";
import Footer from "./components/Footer";
function App() {
  return (
    
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<PageOne />} />
        <Route path="/bridging-the-gap" element={<PageTwo />} />
        <Route path="/beauty-with-a-purpose" element={<PageThree/>}/>
        <Route path="/gallery" element={<PageFour/>}/>
        <Route path="/in-focus" element={<PageFive/>}/>
        <Route path="/donate" element={<PageSix/>}/>
      </Routes>
      <Footer/>

    </BrowserRouter>
  );
}

export default App;
