import React, {Component} from "react";
import SectionOne from "./Content";
import Carousel from './Carousel'

class PageThree extends Component {
    render() {
        return(
            <>
            <SectionOne/>
            <div style={{maxWidth: '500px', margin: 'auto'}}>
                <Carousel/>
            </div>
            
            </>
        )
    }
}
    
export default PageThree;