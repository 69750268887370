import React, { Component } from "react";
import './Gallery.css'; // Create a CSS file for styling
import img1 from './caoursel-imgs/1/IMG_6020.jpg';
import img2 from './caoursel-imgs/1/IMG_6021.jpg';
import img3 from './caoursel-imgs/1/IMG_6025.jpg';
import img4 from './caoursel-imgs/2/1 • GMA 7 “UNANG HIRIT” Guesting (Photo allot_ 3)/IMG_5116.jpg';
import img5 from './caoursel-imgs/2/1 • GMA 7 “UNANG HIRIT” Guesting (Photo allot_ 3)/IMG_5117.jpg';
import img6 from './caoursel-imgs/2/1 • GMA 7 “UNANG HIRIT” Guesting (Photo allot_ 3)/IMG_5118.jpg';
import img7 from './caoursel-imgs/2/2 • PRESS CONFERENCE (photo allot_5)/IMG_4883.jpg';
import img8 from './caoursel-imgs/2/2 • PRESS CONFERENCE (photo allot_5)/IMG_4922.jpg';
import img9 from './caoursel-imgs/2/2 • PRESS CONFERENCE (photo allot_5)/IMG_4949.jpg';
import img10 from './caoursel-imgs/2/2 • PRESS CONFERENCE (photo allot_5)/IMG_5400.jpg';
import img11 from './caoursel-imgs/2/2 • PRESS CONFERENCE (photo allot_5)/IMG_5416.jpg';
import img12 from './caoursel-imgs/3/IMG_4951.jpg';
import img13 from './caoursel-imgs/3/IMG_4954.jpg';
import img14 from './caoursel-imgs/3/IMG_4969.jpg';
import img15 from './caoursel-imgs/3/IMG_4973.jpg';
import img16 from './caoursel-imgs/3/IMG_5406.jpg';
import img17 from './caoursel-imgs/3/IMG_5408.jpg';
import img18 from './caoursel-imgs/4/IMG_5005.jpg';
import img19 from './caoursel-imgs/4/IMG_5008.jpg';
import img20 from './caoursel-imgs/4/IMG_5410.jpg';
import img21 from './caoursel-imgs/4/IMG_5417.jpg';
import img22 from './caoursel-imgs/4/IMG_5419.jpg';
import img23 from './caoursel-imgs/5/IMG_6092.png';
import img24 from './caoursel-imgs/5/IMG_6094.png';

class Gallery extends Component {
  render() {
    const imageUrls = [
      img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24
    ];

    return (
      <div className="gallery-container">
        {imageUrls.map((url, index) => (
          <div
            key={index}
            className={`gallery-item ${index % 2 === 0 ? "offset" : ""}`}
          >
            <img src={url} alt={`Image ${index + 1}`} loading='lazy' />
          </div>
        ))}
      </div>
    );
  }
}

export default Gallery;
