import React, { Component } from 'react';
import heroImage from './img/hero-image.png'
import { Link } from 'react-router-dom';

class Hero extends Component {
    render() {
        const spanStyle1 = {
            color: '#eb2192'
        };

        const spanStyle2 = {
            color: '#29abeb'
        };

        const buttonStyle1 = {
            borderRadius: '30px',
            width: '200px',
            backgroundColor: '#24943d',
            marginRight: '30px'
        };

        const buttonStyle2 = {
            borderRadius: '30px',
            width: '200px'
        };

        return (
            <div className="container-fluid" id="hero-biggest-container">
                <div className="row" id="hero-main-container">
                    <div className='col-md-8' id="hero-content">
                        <div id="hero-left-content">
                             <h1 id="hero-hashtag"><strong>
                                #<span style={spanStyle1}>Bridge</span>TheGap
                                <span style={spanStyle2}>Build</span>TheFuture
                            </strong></h1>
                            <h4 id='sub-hero-hashtag'>Creating Opportunities, Transforming Lives through the Power of Education</h4>
                            <p style={{ margin: '30px 0px 30px 0px' }}>Join us in empowering every young Filipino to create a brighter
                                tomorrow!</p>
                                
                            <div className="d-flex" id="hero-main-buttons">
                                <Link to="/donate" className="btn button text-light" style={buttonStyle1}>Donate Now!</Link>
                                <a href="#footer-section" className="btn btn-outline-dark button" style={buttonStyle2}>Contact Us</a>
                            </div>
                        </div>
                       
                    </div>
                    <div className="col-md-4" style={{ padding: '0'}} id="hero-column-to-hide">
                        <img src={heroImage} style={{ width: '380px', padding: '0', float: 'right', display: 'block'}} id="hero-image" alt='hero' loading='lazy'/>
                        <div style={{position: 'absolute', top: '400px', right: '0', backgroundColor: '#eb2192', borderTopLeftRadius: '70px', borderBottomLeftRadius: '70px', padding: '15px', color: 'white', textShadow: '2px 2px gray', width: '450px'}}>
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h1><strong>Php 932,766.00</strong></h1>
                                    <p>DONATION UPDATE as of Aug 30, 2023</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div id='donation-pill-sm' style={{backgroundColor: '#eb2192', borderRadius: '0px', padding: '10px', color: 'white', textShadow: '2px 2px gray', width: '400px', marginLeft: '20px', margin: '20px'}}>
                        <div className="d-flex justify-content-center">
                            <div style={{textAlign: 'center'}}>
                                <h5><strong>Php 932,766.00</strong></h5>
                                <p>DONATION UPDATE as of Aug 30, 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Hero;
