import React, {Component} from "react";
import bannerImg from './img/banner.png';
import section1Img from './img/section1.png'
import section2Img from './img/section2.png'
import section3Img from './img/section3.png'
import section4Img from './img/section4.png'
class Banner extends Component{
    render() {
        return(
            <>
                <div className="container-fluid" style={{padding: '0'}} loading='lazy'>
                    <img src={bannerImg} style={{width: '100%'}} />
                </div>
                <div className="container-fluid" style={{padding: '0'}} loading='lazy'>
                    <img src={section1Img} style={{width: '100%'}} />
                </div>
                <div className="container-fluid" style={{padding: '0'}} loading='lazy'>
                    <img src={section2Img} style={{width: '100%'}} />
                </div>
                <div className="container-fluid" style={{padding: '0'}} loading='lazy'>
                    <img src={section3Img} style={{width: '100%'}} />
                </div>
                <div className="container-fluid" style={{padding: '0'}} loading='lazy'>
                    <img src={section4Img} style={{width: '100%'}} />
                </div>
               
            </>
        )
    }
}

export default Banner;