import React, { Component } from "react";
import bannerImg from './img/banner.png';
class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number1: 0,
      number2: 0,
      number3: 0,
      targetNumber1: 2000,
      targetNumber2: 40,
      targetNumber3: 1500,
    };
  }

  componentDidMount() {
    // Start the number animation when the component mounts
    this.animateNumbers();
  }

  animateNumbers = () => {
    const animationDuration = 2000; // in milliseconds
    const updateInterval = 50; // in milliseconds
    const totalFrames = animationDuration / updateInterval;

    const step1 = (this.state.targetNumber1 - this.state.number1) / totalFrames;
    const step2 = (this.state.targetNumber2 - this.state.number2) / totalFrames;
    const step3 = (this.state.targetNumber3 - this.state.number3) / totalFrames;

    let frame = 0;
    const intervalId = setInterval(() => {
      if (frame < totalFrames) {
        this.setState((prevState) => ({
          number1: prevState.number1 + step1,
          number2: prevState.number2 + step2,
          number3: prevState.number3 + step3,
        }));
        frame++;
      } else {
        // Ensure the final state is exactly the target numbers
        this.setState({
          number1: this.state.targetNumber1,
          number2: this.state.targetNumber2,
          number3: this.state.targetNumber3,
        });
        // Stop the interval when the animation is complete
        clearInterval(intervalId);
      }
    }, updateInterval);
  };

  render() {
    return (
      <>
        <div style={{ height: "300px", paddingTop: '40px', width: '100%'}} className="container-fluid">
          <div className="container-fluid d-flex justify-content-center" style={{ padding: "0", textAlign: "center" }}>
            <h2 className="header-shrink">
              With the kind generosity of our donors and partners, #BTGBTF
              <br></br> has impacted young Filipinos across the country:
            </h2>
          </div>
          <div className="container-fluid d-flex justify-content-around" >
            <div>
                <h1 style={{color: '#29abeb'}} className="display-1 stat-header">{Math.round(this.state.number1)}+</h1>
                <p className="stats">No. of Young Filipinos Helped</p>
            </div>
            <div>
                <h1 style={{color: '#eb2192', textAlign: 'center'}}  className="display-1 stat-header">{Math.round(this.state.number2)}+</h1>
                <p className="stats">No. of Communities Visited &nbsp; &nbsp;</p>
            </div>
            <div>
                <h1 style={{color: '#42943d'}} className="display-1 stat-header">{Math.round(this.state.number3)}+</h1>
                <p className="stats">No. of Learning Kits/Packs Shared</p>
            </div>
          </div>
        </div>
        <div style={{padding: '0'}}>
          <img src={bannerImg} style={{width: '100%'}} />
        </div>
      </>
    );
  }
}

export default Stats;
