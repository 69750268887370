import React, { Component } from "react";
import img1 from './carousel-shots/IMG_5981.jpg'
import img2 from './carousel-shots/IMG_6080.png'
import img3 from './carousel-shots/IMG_6085.png'

class Carousel extends Component {
  render() {
    const imageUrls = [
      img1, img2, img3
    ];

    const carouselItems = [];

    for (let i = 0; i < imageUrls.length; i++) {
      const imageUrl = imageUrls[i];
      const isActive = i === 0;

      carouselItems.push(
        <div key={i} className={`carousel-item ${isActive ? 'active' : ''}`} style={{ maxHeight: '600px'}}>
          <img src={imageUrl} className="d-block w-100" alt={`Slide ${i + 1}`} style={{ height: '100%', width: '100%', objectFit: 'cover', overflow: 'hidden'}} loading='lazy'/>
        </div>
      );
    }

    const carouselIndicators = [];

    for (let i = 0; i < imageUrls.length; i++) {
      const isActive = i === 0;

      carouselIndicators.push(
        <button
          key={i}
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to={i}
          className={isActive ? 'active' : ''}
          aria-label={`Slide ${i + 1}`}
        ></button>
      );
    }

    return (
      <>
        <div id="carouselExampleIndicators" className="carousel slide" style={{borderColor:'blue', width: '50%', margin: 'auto'}}>
          <div className="carousel-indicators">{carouselIndicators}</div>
          <div className="carousel-inner">{carouselItems}</div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </>
    );
  }
}

export default Carousel;
