import React, {Component} from "react";
import Gallery from "./Gallery";

class PageFour extends Component {
    render() {
        return(
            <>
                <Gallery></Gallery>
            </>
        )
    }
}


export default PageFour;