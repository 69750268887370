import React, { Component } from 'react';
import btn1 from './img/hero-btn-3.png'
import btn2 from './img/hero-btn-2.png'
import btn3 from './img/hero-btn-1.png'

class HeroButtons extends Component {
    render() {
        return (
        <div className="container-fluid">
            <div className="row hero-btn">
                <div className="col-sm-4" style={{ padding: 0}}>
                    <a href="#inspiSection"><img src={btn1} className="img-fluid"/></a>
                </div>
                <div className="col-sm-4 hero-btn" style={{ padding: 0}}>
                    <a href='#gapsSection'><img src={btn2} className="img-fluid"/></a>
                </div>
                <div className="col-sm-4 hero-btn" style={{ padding: 0}}>
                    <a href="#initiativesSection"><img href='#initiativesSection' src={btn3} className="img-fluid"/></a> 
                </div>
            </div>
        </div>
        );
    }
}

export default HeroButtons;
