import React, {Component} from "react";
import banner1Img from './img/banner.png';
import banner2Img from './img/banner2.png';
import banner3Img from './img/banner3.jpg';
import banner4Img from './img/banner4.jpg';
import content1Img from './img/content1.png';
import content2Img from './img/content2.png';
import content3Img from './img/content3.png';
import content4Img from './img/content4.png';
import sponsorsImg from './img/sponsors.png';
class SectionOne extends Component{
    render(){
        return(
            <>
            {/* Section 1 */}
            <div className="container-fluid" style={{padding: '0'}}>
                <img src={banner1Img} style={{width: '100%'}}/>
            </div>
           <div className="container-fluid" style={{padding: '0'}}>
                <img src={content1Img} style={{width: '100%'}}/>
            </div>


            {/* Section 2 */}
            <div className="container-fluid" style={{padding: '0'}}>
                <img src={banner2Img} style={{width: '100%'}}/>
            </div>
            <div className="container-fluid" style={{padding: '0'}}>
                <img src={content2Img} style={{width: '100%'}}/>
            </div>

            {/* Section 3 */}
            <div className="container-fluid" style={{padding: '0'}}>
                <img src={banner3Img} style={{width: '100%'}}/>
            </div>
            <div className="container-fluid" style={{padding: '0'}}>
                <img src={content3Img} style={{width: '100%'}}/>
            </div>

            {/* Section 4 */}
             <div className="container-fluid" style={{padding: '0'}}>
                <img src={banner4Img} style={{width: '100%'}}/>
            </div>
            <div className="container-fluid" style={{padding: '0'}}>
                <img src={content4Img} style={{width: '100%'}}/>
            </div>

            <div className="container-fluid d-flex justify-content-center">
                <img src={sponsorsImg} style={{width: '100%'}}/>
            </div>
            </>
        )
    }
}

export default SectionOne;