import React, { Component } from "react";
import img1 from './carousel-imgs/1 — THE SITE - SOON TO RISE/IMG_1566.jpg'
import img2 from './carousel-imgs/1 — THE SITE - SOON TO RISE/IMG_4011.jpg'
import img3 from './carousel-imgs/2 — MOU SIGNING with BGY BASECO/IMG_3346.jpg'
import img4 from './carousel-imgs/2 — MOU SIGNING with BGY BASECO/IMG_4027.jpg'
import img5 from './carousel-imgs/3 — CONSTRUCTION from Groundbreaking - Halfway - Near Completion/IMG_5690.jpg'
import img6 from './carousel-imgs/3 — CONSTRUCTION from Groundbreaking - Halfway - Near Completion/IMG_5698.jpg'
import img7 from './carousel-imgs/4 —- MURAL - NBS tie up /IMG_4930.jpg'
import img8 from './carousel-imgs/4 —- MURAL - NBS tie up /IMG_4933.jpg'
import img9 from './carousel-imgs/5 —- TURNOVER /IMG_5411.jpg'
import img10 from './carousel-imgs/5 —- TURNOVER /IMG_5412.jpg'

class Carousel extends Component {
  render() {
    const imageUrls = [
      img1, img2, img3, img4, img5, img6, img7, img8, img9, img10
    ];

    const carouselItems = [];

    for (let i = 0; i < imageUrls.length; i++) {
      const imageUrl = imageUrls[i];
      const isActive = i === 0;

      carouselItems.push(
        <div key={i} className={`carousel-item ${isActive ? 'active' : ''}`} style={{height: '400px'}}>
          <img src={imageUrl} className="d-block w-100" alt={`Slide ${i + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden'}} loading='lazy'/>
        </div>
      );
    }

    const carouselIndicators = [];

    for (let i = 0; i < imageUrls.length; i++) {
      const isActive = i === 0;

      carouselIndicators.push(
        <button
          key={i}
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to={i}
          className={isActive ? 'active' : ''}
          aria-label={`Slide ${i + 1}`}
        ></button>
      );
    }

    return (
      <>
        <div id="carouselExampleIndicators" className="carousel slide" style={{ margin: 'auto', width: '100%'}}>
          <div className="carousel-indicators">{carouselIndicators}</div>
          <div className="carousel-inner">{carouselItems}</div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </>
    );
  }
}

export default Carousel;
