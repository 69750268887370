import React, { Component } from "react";
import whatWeDoImg from './img/what-we-do.jpg';
import howWeDoItImg from './img/how-we-do-it.jpg';
import whatWeAimToAchieveImg from './img/what-we-aim-to-achieve.jpg';

class Initiatives extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div style={{ margin: '40px 0px 40px 0px', textAlign: 'center' }}>
                        <h1 id="initiativesSection"><strong>Our Initiatives</strong></h1>
                        <p>THE VISION: “A Country where Every Filipino has fair and full access to Quality Education”</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12" style={{ padding: '0' }}>
                        <img src={whatWeDoImg} className="img-fluid" alt="What We Do"style={{width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden'}} />
                    </div>
                    <div className="col-lg-6 col-md-12 text-light" style={{ backgroundColor: '#29abeb', padding: '5% 10%' }}>
                        <h1><strong>What We Do</strong></h1>
                        <h5 style={{textAlign: 'justify'}} className="initiative-text1">Our initiatives aim to pave the way toward a better future, for as many Filipinos as possible through access to fair and quality education.</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 text-light arrange2" style={{ backgroundColor: '#eb2192', padding: '5% 10%' }}>
                        <h1><strong>How We Do It</strong></h1>
                        <h5 style={{textAlign: 'justify'}} className="initiative-text2">In collaboration with different stakeholders, this project aims to raise funds and collect learning materials, mainly to address the 3 areas of concern in education: INFRASTRUCTURE, TEACHERS & LEARNING TOOLS. The #BTGBTF team aims to reach out to as many underprivileged parts of the Philippines to distribute collected learning materials and funds.</h5>
                    </div>
                    <div className="col-lg-6 col-md-12 arrage2" style={{ padding: '0' }}>
                        <img src={howWeDoItImg} className="img-fluid" alt="How We Do It" style={{width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden'}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12" style={{ padding: '0' }}>
                        <img src={whatWeAimToAchieveImg} alt="What We Aim to Achieve" className="img-fluid" style={{width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden'}}/>
                    </div>
                    <div className="col-lg-6 col-md-12 text-light" style={{ backgroundColor: '#24943d', padding: '5% 10%' }}>
                        <h1><strong>What We Aim to Achieve</strong></h1>
                        <h5 style={{textAlign: 'justify'}} className="initiative-text3">The bigger objective: using part of the funds raised and educational resources gathered, the project targets to complete the ERDA BASECO Learning Hub. This new building will cater to the youth of Baseco Compound, an impoverished area in Manila.</h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default Initiatives;
    