import React, {Component} from "react";
import bannerImg from './img/banner.png';
import banner2Img from './img/banner2.png';
import qrImg from './img/qrs.png';

class PageSix extends Component {
    render() {
        return(
            <>
                <div className="container-fluid" style={{padding: '0'}}>
                    <img src={bannerImg} style={{width: '100%'}} loading='lazy' />
                </div>
               <div className="container-fluid" style={{padding: '0'}}>
                    <img src={banner2Img} style={{width: '100%'}} loading='lazy' />
                </div>
                <div className="container-fluid" style={{padding: '0'}}>
                    <img src={qrImg} style={{width: '100%'}} loading='lazy'/>
                </div>
            </>
        )
    }
}


export default PageSix;