import React, {Component} from "react";
import timelineImg1 from './img/timeline-1.png'
import timelineImg2 from './img/timeline-2.png'
// import Carousel from "../page3/Carousel";
class Timeline extends Component {
    render() {
        const arrange = {textAlign: 'left'}
        const space = {margin: '15px'}
        return(
            <div className="container-fluid" >
               <div className="row" style={{ padding: '0'}}>
                    <div className="col-lg-6" style={{ padding: '0'}}>
                        <img src={timelineImg1} style={{width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden'}} loading='lazy' />
                    </div>
                    <div className="col-lg-6" style={{padding: '50px', margin: '0'}}>
                        <h1 className="display-5" style={arrange}>The Beginning Milestones</h1>
                        <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Jun 2021</strong></div>
                            <div className="col-md-9" style={arrange}>ERDA SABANA Children</div>
                        </div>
                        <div className="row"style={space}>
                            <div className="col-md-3" style={arrange}><strong>May 2022</strong></div>
                            <div className="col-md-9" style={arrange}>ERDA Youth Day | Concentrix ERDA Women Leaders of Manila 3 (Baseco)</div>
                        </div>
                        <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Aug 2022</strong></div>
                            <div className="col-md-9" style={arrange}>School Supplies Distribution to ERDA communities of Manila and Muntinlupa City</div>
                        </div>
                        <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Sep 2022</strong></div>
                            <div className="col-md-9" style={arrange}>School Supplies relief distribution to children affected by flood in Pandacan, Manila and fire in Navotas</div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 arrange3" style={{borderColor: 'red', padding: '50px'}}>
                        <h1 className="display-5" style={arrange}>Gaining Momentum</h1>
                        <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Nov 2022</strong></div>
                            <div className="col-md-9" style={arrange}>Project Rise Up 2022 | Trinity University of Asia</div>
                        </div>
                        <div className="row"style={space}>
                            <div className="col-md-3" style={arrange}><strong>Dec 2022</strong></div>
                            <div className="col-md-9" style={arrange}>Gift-giving to the children of ERDA Baseco</div>
                        </div>
                        <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Mar 2023</strong></div>
                            <div className="col-md-9" style={arrange}>TAMTALKS-FEU Diliman: Talk on Advocating for Education/Fundraising Campaign for #BTGBTF</div>
                        </div>
                        <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Apr 2023</strong></div>
                            <div className="col-md-9" style={arrange}>Himamaylan Immersion with the Youth (with fellow 2022 queens)</div>
                        </div>
                         <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Aug 2023</strong></div>
                            <div className="col-md-9" style={arrange}>Iloilo Outreach School Supplies Distribution</div>
                        </div>
                         <div className="row" style={space}>
                            <div className="col-md-3" style={arrange}><strong>Sep 2023</strong></div>
                            <div className="col-md-9" style={arrange}>Bacolod- Indigenous Community of Himamaylan 
                         Outreach School Supplies Distribution <br />School Shoes & Loot Bags Distribution, ERDA  Baseco | Concentrix</div>
                        </div>
                    </div>
                    <div className="col-lg-6 arrange3" style={{ padding: '0'}}>
                        <img src={timelineImg2} style={{width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden'}} loading='lazy' />
                    </div>
                </div>
            </div>
                
             
        )
    }
}


export default Timeline;