import React, {Component} from "react";
import Banner from "./Banner";
import Carousel from "./Carousel";

class PageFive extends Component{
    render() {
        return(
            <>
               <Banner></Banner>
              <Carousel></Carousel> 
            </>
        )
    }
}

export default PageFive;