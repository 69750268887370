import React, { Component } from 'react';
import gapsImg from './img/gaps.png'
class Gaps extends Component {
  render() {
    return (
      <div className="container-fluid" style={{backgroundColor: '#f2f3f5', padding: '20px 0px 20px 0px'}}>
        <div style={{ margin: '2% 15% 2% 15%' }}>
          <div className="row">
            <div className="col-lg-6">
              <h1 id="gapsSection" style={{ textAlign: 'justify' }}>
                <strong>Educational Gaps In the Philippines</strong>
              </h1>
              <strong><p className="font-weight-bold">84 MILLION: Young Filipinos at risk of being out of school by the year 2030 (Source: UN Philippines)</p></strong>
              <p>THE MAJOR CAUSE: the lack of access to fair and quality education</p>
              <p>Working with ERDA Foundation for years opened Gwen’s eyes to the 3 biggest gaps in PH education:</p>
              <p>If these gaps are not addressed today, the future of 84 million young Filipinos will be put in danger.</p>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <img src={gapsImg} style={{height: '300px', padding: '0'}}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gaps;
