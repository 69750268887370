import React, { Component } from 'react';
import icon from './page1/img/icon.png'
import { Link } from 'react-router-dom';
class Nav extends Component {
    render() {
        return(
            <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
            <Link class="navbar-brand" to="/">
                <img src={icon} width="70" height="50"></img>
            </Link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav mx-auto d-flex">
                    <li class="nav-item">
                        <Link class="nav-link active" aria-current="page" to="/">Home</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/bridging-the-gap">Bridging the Gap</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/beauty-with-a-purpose">Beauty with a Purpose</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/gallery">Gallery</Link>
                    </li>
                    <li class="nav-item">
                        <Link class="nav-link" to="/in-focus">In Focus</Link>
                    </li>
                     <li class="nav-item">
                        <Link class="nav-link" to="/donate">Donate Now</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
        )
    };
}

export default Nav;