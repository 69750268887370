import React, {Component} from "react";
import fbIcon from './fbIcon.png'
import igIcon from './ig.png'
import logoImg from './page1/img/icon.png'

class Footer extends Component {
    render() {
        return(
            <>
            <div className="container d-flex justify-content-center" style={{textAlign: 'center'}}>
                <div style={{margin: '20px'}}>
                    <h5 className="footer">Bridge the Gap and Build the Future With Us!</h5>
                    <div className="d-flex justify-content-center">
                        <a target="_blank" href="https://www.facebook.com/GwendolyneFourniol"><img src={fbIcon} width='50' height='50' style={{marginRight: '10px'}} /></a>
                        <a target="_blank" href="https://www.instagram.com/gwendolynefourniol/"><img src={igIcon} width='50' height='50' style={{marginRight: '10px'}} /></a>
                       <img src={logoImg} width='70' height='50'/>
                    </div>

                </div>
            </div>
            <div className="d-flex justify-content-center" id='footer-section' style={{borderTop: 'solid 2px', margin: '0px 5% 0px 5%'}}>
                 <div style={{margin: '10px', paddingTop: '10px', marginBottom: '20px'}}>
                    <h5 className="footer">To <span style={{color: '#eb2192'}}><strong>educate</strong></span> a child is to <span style={{color: '#eb2192'}}><strong>empower</strong></span> next generations.</h5>
                </div>
            </div>
            </>
        )
    }
}

export default Footer;