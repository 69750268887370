import React, { Component } from 'react';
import theInspi1 from './img/the-inspiration.jpg';
import theInspi2 from './img/the-inspiration-2.jpg';

class TheInspiration extends Component {
    render() {
        return (
            <div className="container-fluid" style={{ marginBottom: '50px' }}>
                <div id="inspi-main-container">
                    <div className="row inspi1" style={{ marginBottom: '20px' }}>
                        <div className="col-lg-6" style={{ textAlign: 'justify' }}>
                            <h1 id='inspiSection' style={{marginTop:'10px'}}><strong>The Inspiration</strong></h1>
                            <p>Driven to empower children through education, Gwen Fourniol draws inspiration from two of her biggest influences—her mom Sim Fourniol and Fr. Pierre Tritz, Founder of ERDA Foundation, Inc.</p>
                            <p>“My mom grew up in poverty in Kabankalan, Negros Occidental. There were seven children in the family, with only one sibling given the opportunity to study.”</p>
                            <p>Losing hope for her future, Gwen’s mom found her way to Manila, and met Fr. Pierre Tritz SJ, who founded ERDA Foundation to give underprivileged but deserving children the chance to study.</p>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center">
                            <img src={theInspi1} className="img-fluid" alt="Inspiration 1" style={{ height: '400px' }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-center arrange">
                            <img src={theInspi2} style={{ height: '230px'}} />
                        </div>
                        <div className="col-lg-6 inspi1 arrange" style={{ textAlign: 'justify' }}>
                            <p>With ERDA’s help, Sim earned her Psychology degree from the Philippine Women’s University. She got the chance to work in Luxembourg, where she met her future husband, Thierry Fourniol.</p>
                            <p>Today, as ERDA Ambassador for Education, Gwen aims to share the same transformative power of education through her advocacy, #BridgeTheGapBuildTheFuture — and bring this same opportunity to as many young Filipinos as possible.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TheInspiration;
