import React, {Component} from "react";
import Nav from "../Nav";
import Hero from "./Hero";
import TheInspiration from "./TheInspiration";
import Gaps from "./Gaps";
import HeroButtons from "./HeroButtons";
import Initiatives from "./Initiatives";

class PageOne extends Component{
    render() {
        return(
            <>
                <Hero></Hero>
                <HeroButtons></HeroButtons>
               <TheInspiration></TheInspiration>
                <Gaps></Gaps>
                <Initiatives></Initiatives>
            </>
        )
    }
}

export default PageOne;